import React from "react"
import { Grid, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles/";
import Layout from "../components/layout"
import { Link as RouterLink } from "gatsby"
import HomeIcon from "@material-ui/icons/Home";
import Seo from "../components/seo"

const useStyles = makeStyles(theme => ({
  container: {
    padding: "2rem",
  },
  paragraph: {
    margin: "1rem auto",
    maxWidth: "70vw",
  },
  links: {
    textDecoration: "none",
    color: "inherit",
    ":hover": {
      textDecoration: "underline",
    },
  },
}))

export default function Terms() {
  const classes = useStyles()
  return (
    <Layout>
      <Seo title="Terms of Use" />
      <Grid container className={classes.container}>
        <Grid item className={classes.paragraph}>
          <Button
            variant="outlined"
            style={{ margin: "2rem 0" }}
            startIcon={<HomeIcon />}
          >
            <RouterLink className={classes.links} to="/">
              Back to Home
            </RouterLink>
          </Button>
          <Typography variant="h4">
            Website usage terms and conditions
          </Typography>
          <Typography>
            Welcome to our website. If you continue to browse and use this
            website, you are agreeing to comply with and be bound by the
            following terms and conditions of use, which together with
            ourprivacy policy govern this site's owner's relationship with you
            in relation to this website. If you disagree with any part of these
            terms and conditions, please do not use our website. The term 'us'
            or 'we' refers to the owner of the website. The term 'you' refers to
            the user or viewer of our website. The use of this website is
            subject to the following terms of use:
            <ul>
              <li>
                The content of the pages of this website is for your general
                information and use only. It is subject to change without
                notice.
              </li>
              <li>
                Neither we nor any third parties provide any warranty or
                guarantee as to the accuracy, timeliness, performance,
                completeness or suitability of the information and materials
                foundor offered on this website for any particular purpose. You
                acknowledge that such information and materials may contain
                inaccuracies or errors and we expressly exclude liability for
                any such inaccuracies or errors to the fullest extent permitted
                by law.
              </li>
              <li>
                Your use of any information or materials on this website is
                entirely at your own risk,for which we shall not be liable. It
                shall be your own responsibility to ensure that any products,
                services or information available through this website meet your
                specific requirements.
              </li>
              <li>
                This website contains material which is owned by or licensed to
                us. This material includes, but is not limited to, the design,
                layout, look, appearance and graphics. Reproduction is
                prohibited other than in accordance with the copyright notice,
                which forms part of these terms and conditions.
              </li>
              <li>
                All trademarks reproduced in this website, which are not the
                property of, or licensed to the operator, are acknowledged on
                the website.
              </li>
              <li>
                Unauthorized use of this website may give rise to a claim for
                damages and/or be a criminal offence.
              </li>
              <li>
                From time to time, this website may also include links to other
                websites. These links are provided for your convenience to
                provide further information. They do not signify that we endorse
                the website(s). We have no responsibility for the content of the
                linked website(s).
              </li>
              <li>
                Your use of this website and any dispute arising out of such use
                of the website is subject to the laws of Canada.
              </li>
            </ul>
          </Typography>
        </Grid>
      </Grid>
    </Layout>
  )
}
